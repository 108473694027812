@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: 26, 26, 26, 1;
    --color-secondary: 102, 102, 102, 1;
    --color-background: 255, 255, 255, 1;
    --color-border: 0, 0, 0, 0.15;
    --color-placeholder: 0, 0, 0, 0.3;
    --color-green: 53, 163, 77, 1;
    --color-blue: 24, 125, 243, 0.9;
  }

  :root[class~="dark"] {
    --color-primary: 255, 255, 255, 1;
    --color-secondary: 161, 161, 161, 1;
    --color-background: 0, 0, 0, 0.9;
    --color-border: 255, 255, 255, 0.3;
    --color-placeholder: 255, 255, 255, 0.3;
    --color-green: 53, 163, 77, 1;
    --color-blue: 24, 125, 243, 0.9;
  }
}

body,
html {
  height: 100%;
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background);
  overflow: hidden;
  transition: margin 250ms ease-in-out;

  @media screen and (max-width: 768px) {
    transition: none;
  }
}

#frameContainer {
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  transition: opacity 250ms ease-in-out;
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
}

.frameContainer-open {
  opacity: 1;
  z-index: 9999;

}

.frameContainer-closed {
  opacity: 0;
  z-index: -1;
}

/* iframe {
  border: none;
  min-width: 550px;
  max-width: 550px;
  height: 100vh;
} */

.mainstage {
  border: none;
  width: 100vw;
  height: 100vh;

  max-width: 550px;
  min-width: 280px;

  overflow: hidden;
  background-color: white;
}

#flutter_target {
  display: none;
  width: 100%;
}

#welcome {
  display: none;
  width: 100%;
}

#welcome-header-label {
  color: black;
  font-size: 32px;
  margin-bottom: 0px;
}

#welcome-title-label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 15px;
  margin-bottom: 32px;
}

#welcome-invite-label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

/* #welcome-container {
  background-color: 'yellow';
} */

@media screen and (max-width: 550px) {
  #frameContainer {
    height: 100%;
  }

  .mainstage {
    border: none;
    width: 100%;
    min-width: 280px;
    max-width: none;
    overflow: auto;
  }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

/* Dark theme when user prefers dark color scheme */
@media (prefers-color-scheme: dark) {
  body,
  html {
    background-color: #333333;
  }

  .mainstage {
    background-color: black;
  }

  #welcome-header-label {
    color: white;
  }

  #frameContainer {
    background-color: #333333;
  }

  #welcome-title-label {
    color: white;
  }

  #welcome-container {
    background-color: black;
  }

  .simplebar-scrollbar:before {
    background: rgba(255, 255, 255) !important;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

.react-datepicker {
  background-color: rgb(var(--color-background)) !important;
  font-family: "IBM Plex Sans", sans-serif !important;
  border: none !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1) !important;
}

.react-datepicker__triangle {
  display: none !important;
  border-bottom-color: rgb(var(--color-border)) !important;
}

.react-datepicker__header {
  border-radius: 10px 10px 0 0 !important;
  border-bottom: none !important;
  font-weight: 300 !important;
}

.react-datepicker__current-month {
  color: rgb(var(--color-primary)) !important;
  font-weight: 500 !important;
}

.react-datepicker__day-name {
  color: rgb(var(--color-secondary)) !important;
}

.react-datepicker__day--selected {
  background-color: rgb(var(--color-blue)) !important;
  color: rgb(var(--color-background)) !important;
}

.react-datepicker__month {
  color: rgb(var(--color-primary)) !important;
  font-weight: 300 !important;
  margin:0 !important;
}

.ContextMenuTrigger {
  display: block;
  border: 2px white dashed;
  color: white;
  border-radius: 4px;
  font-size: 15px;
  user-select: none;
  padding: 45px 0;
  width: 300px;
  text-align: center;
}

.ContextMenuContent,
.ContextMenuSubContent {
  min-width: 220px;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.ContextMenuItem,
.ContextMenuCheckboxItem,
.ContextMenuRadioItem,
.ContextMenuSubTrigger {
  font-size: 13px;
  line-height: 1;
  color: rgb(var(--color-primary));
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 5px;
  position: relative;
  padding-left: 25px;
  user-select: none;
  outline: none;
}
.ContextMenuItem[data-disabled],
.ContextMenuCheckboxItem[data-disabled],
.ContextMenuRadioItem[data-disabled],
.ContextMenuSubTrigger[data-disabled] {
  pointer-events: 'none';
}
.ContextMenuItem[data-highlighted],
.ContextMenuCheckboxItem[data-highlighted],
.ContextMenuRadioItem[data-highlighted],
.ContextMenuSubTrigger[data-highlighted] {
  background-color: rgb(var(--color-blue));
  color: rgb(var(--color-background));
}

.ContextMenuLabel {
  padding-left: 25px;
  font-size: 12px;
  line-height: 25px;
  color: rgb(var(--color-secondary));
}

.ContextMenuSeparator {
  height: 1px;
  background-color: rgb(var(--color-border));
  margin: 5px;
}

.ContextMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
